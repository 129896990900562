@import '~antd/dist/antd.css';
body {
  margin: 0;
  padding: 0;
}

.site-layout-header {
  background: #fafafa;
  box-shadow: 0 2px 8px #f0f1f2;
  z-index: 10;
}

.site-layout-header a svg {
  height: 28px;
  position: relative;
  top: 8px;
}

.site-layout-header .ant-menu {
  background: #fafafa;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
